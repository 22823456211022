import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicStorageModule } from "@ionic/storage";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { PreviousRouteService } from "./services/previous-route/previous-route.service";
import { DirectivesModule } from "./directives/directives.module";

import { Network } from "@ionic-native/network/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    DirectivesModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({ animated: false }),
    IonicStorageModule.forRoot({
      name: "_incabsupport",
      driverOrder: ["indexeddb", "websql", "sqlite"],
    }),
    AppRoutingModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AppVersion,
    PreviousRouteService,
    SocialSharing,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    Network,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
