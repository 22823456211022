import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

// enable google tag manager analytics
document.write(
  `<script async src="https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsKey}"></script>`
);

// dynamically add title fo app for web
document.write(`<title>${environment.appTitle}</title>`);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
