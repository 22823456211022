import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/app/tabs/home",
    pathMatch: "full",
  },
  {
    path: "app",
    loadChildren: "./pages/tabs/tabs.module#TabsPageModule",
  },
  {
    path: "articles/:articlesId",
    loadChildren: "./pages/articles/articles.module#ArticlesPageModule",
  },
  {
    path: "article/:articleId",
    loadChildren: "./pages/article/article.module#ArticlePageModule",
  },
  {
    path: "sendfeedback",
    loadChildren:
      "./pages/sendfeedback/sendfeedback.module#SendfeedbackPageModule",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
