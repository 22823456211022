import { Directive, Input, OnChanges, HostBinding } from "@angular/core";
import { environment } from "../../environments/environment";

@Directive({
  selector: "[appTheme]",
})
export class ThemeDirective implements OnChanges {
  @HostBinding("src")
  public imageSource: string;

  @Input()
  public asset: string;

  ngOnChanges() {
    const theme: string = environment.themeDirectory;
    this.imageSource = "./assets/" + theme + "/" + this.asset;
  }
}
