import {
  Directive,
  Input,
  OnChanges,
  ElementRef,
  HostBinding,
} from "@angular/core";
import { Platform } from "@ionic/angular";
import { HttpCacheService } from "../services/http-cache/http-cache.service";
import { environment } from "../../environments/environment";

import ImgCache from "imgcache.js";

@Directive({
  selector: "[appImagecache]",
})
export class ImagecacheDirective implements OnChanges {
  public elementRef: ElementRef;

  @HostBinding("src")
  public imageSource: string;

  @Input()
  public url: string;

  @Input()
  public downloaded: boolean;

  constructor(
    private httpCache: HttpCacheService,
    private elRef: ElementRef,
    private platform: Platform
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    let offline: boolean = this.httpCache.isOffline();
    const theme: string = environment.themeDirectory;
    this.imageSource = this.url;

    if (!this.isNative()) {
      return;
    }

    if (!offline && this.downloaded) {
      ImgCache.isCached(this.imageSource, (success: boolean) => {
        if (success) {
          ImgCache.useCachedFile(this.elRef.nativeElement);
        } else {
          ImgCache.cacheFile(this.imageSource, () => {
            ImgCache.useCachedFile(this.elRef.nativeElement);
          });
        }
      });
    } else if (!offline && !this.downloaded) {
      this.imageSource = this.url;
    } else if (offline) {
      ImgCache.isCached(this.imageSource, (success: boolean) => {
        if (success) {
          ImgCache.useCachedFile(this.elRef.nativeElement);
        } else {
          this.imageSource = "./assets/" + theme + "/offline.jpg";
        }
      });
    } else {
      console.error("there was an error");
    }
  }

  public isNative(): boolean {
    return this.platform.is("cordova");
  }
}
