import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class FetchingService {
  staticTexts: Object = {};
  language: string;
  region: string;
  DEFAULT_LANG: string = environment.defaultLanguageSlug;
  DEFAULT_REGION: string = environment.defaultRegionID;
  BASE_URL: string = environment.baseUrl;

  constructor(private storage: Storage) {}

  async fetchAvailableLanguages() {
    const token = sessionStorage.getItem("sessionId");
    const url = `${this.BASE_URL}/languages?token=${token}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const jsonResponse = await response.json();
    const languages = jsonResponse["sets"]["Languages"];
    if (languages) {
      this.setAvailableLanguages(languages);
      return languages;
    } else {
      console.error("error fetching app languages");
    }
  }

  async fetchAvailableCountries() {
    const token = sessionStorage.getItem("sessionId");
    const url = `${this.BASE_URL}/categories?set_slug=countries&token=${token}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const jsonResponse = await response.json();
    const countries = jsonResponse["sets"]["countries"];
    if (countries) {
      this.setAvailableCountries(countries);
      return countries;
    } else {
      console.error("error fetching app countries");
    }
  }

  async fetchAvailableRegions() {
    const token = sessionStorage.getItem("sessionId");
    const url = `${this.BASE_URL}/categories?set_slug=regions&token=${token}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const jsonResponse = await response.json();
    const regions = jsonResponse["sets"]["regions"];
    if (regions) {
      this.setAvailableRegions(regions);
      return regions;
    } else {
      console.error("error fetching app regions");
    }
  }

  async fetchAvailableTexts(language) {
    const token = sessionStorage.getItem("sessionId");
    const url = `${this.BASE_URL}/static?lang=${language}&token=${token}`;
    const response = await fetch(url, {
      method: "GET",
    });
    const jsonResponse = await response.json();
    const staticTexts = jsonResponse["sets"]["Static Content"];
    if (staticTexts) {
      this.setTranslations(staticTexts);
      return staticTexts;
    } else {
      console.error("error fetching app static texts");
      return null;
    }
  }

  async setAppLanguage() {
    let lang = await this.getLang();
    if (lang) {
      this.setLang(lang);
      return lang;
    }
  }

  async getTranslations(lang = null) {
    if (!lang) {
      lang = await this.getLang();
    }

    try {
      let texts = await this.fetchAvailableTexts(lang);
      if (!texts) {
        texts = this.loadCachedTranslations(lang);
      }
      return texts;
    } catch (e) {
      try {
        return await this.loadCachedTranslations(lang);
      } catch (e) {
        console.error(e);
      }
    }
  }

  async loadCachedTranslations(lang) {
    let texts = await this.storage.get("translationTexts");
    return texts;
  }

  setTranslations(texts) {
    this.storage.set("translationTexts", texts);
  }

  async getAvailableLanguages() {
    return await this.fetchAvailableLanguages();
  }

  setAvailableLanguages(languages: Array<Object>) {
    this.storage.set("languages", languages);
  }

  async getAvailableCountries() {
    return await this.fetchAvailableCountries();
  }

  setAvailableCountries(countries) {
    this.storage.set("countries", countries);
  }

  async getAvailableRegions() {
    return await this.fetchAvailableRegions();
  }

  setAvailableRegions(regions) {
    this.storage.set("regions", regions);
  }

  async getLang() {
    let lang = await this.storage.get("lang");

    if (!lang) {
      const deviceLang = navigator.language.toLowerCase();
      let availableLanguages = await this.storage.get("languages");
      (availableLanguages || []).forEach((language) => {
        if (deviceLang === language["languageCode"]) {
          lang = deviceLang;
        }
      });
    }

    if (!lang) {
      lang = this.DEFAULT_LANG;
    }

    return lang;
  }

  setLang(lang) {
    this.storage.set("lang", lang);
    this.language = lang;
    this.getTranslations(lang);
  }

  async getReg() {
    return await this.storage.get("reg_id");
  }

  setReg(reg) {
    this.storage.set("reg_id", reg);
    this.region = reg;
  }
}
