import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";

import { ArticlesService } from "../articles/articles.service";
import { FetchingService } from "../fetching/fetching.service";
import { HttpCacheService } from "../http-cache/http-cache.service";

import ImgCache from "imgcache.js";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CachingService {
  BASICS_PRODUCTS_COLLECTION = environment.basicsProductsCollection;
  constructor(
    private storage: Storage,
    private fetching: FetchingService,
    private httpCache: HttpCacheService,
    private articles: ArticlesService,
    private platform: Platform
  ) {}

  initializeStorage() {
    if (this.isNative()) {
      ImgCache.options.useDataURI = true;
      ImgCache.init();
      this.httpCache.downloadAllCache();
    }
  }

  async initializeCollections() {
    const supportArticles = await this.articles.fetchCollection(
      "Support Articles"
    );
    this.storage.set("Support Articles", supportArticles);

    const equipmentArticles = await this.articles.fetchCollection(
      "The Basics Articles - Equipment"
    );
    this.storage.set("The Basics Articles - Equipment", equipmentArticles);

    const productArticles = await this.articles.fetchCollection(
      this.BASICS_PRODUCTS_COLLECTION
    );
    this.storage.set(this.BASICS_PRODUCTS_COLLECTION, productArticles);
  }

  async getAgreements() {
    return await this.storage.get("agreementsAccepted");
  }

  setAgreements(bool) {
    this.storage.set("agreementsAccepted", bool);
  }

  getSessionId() {
    return sessionStorage.getItem("sessionId");
  }

  setSessionId(id) {
    sessionStorage.setItem("sessionId", id);
  }

  clearSessionId() {
    sessionStorage.clear();
  }

  async getBookmarks() {
    return await this.storage.get("bookmarks");
  }

  async searchBookmarks(id: number) {
    const allBookmarks = await this.getBookmarks();
    if (!allBookmarks || allBookmarks.length === 0) {
      return null;
    } else {
      const foundBookmark = allBookmarks.find((bookmark) => {
        if (bookmark["id"]) {
          return bookmark["id"] == id;
        } else {
          return bookmark["catID"] == id;
        }
      });

      if (foundBookmark) {
        return foundBookmark;
      } else {
        return null;
      }
    }
  }

  async addToBookmarks(article: object) {
    let searchId: number;
    if (article["id"]) {
      searchId = article["id"];
    } else {
      searchId = article["catID"];
    }
    article["bookmarked"] = true;
    const searchResponse = await this.searchBookmarks(searchId);
    const allBookmarks = await this.getBookmarks();
    if (!allBookmarks) {
      this.storage.set("bookmarks", [article]);
      return;
    } else if (!searchResponse) {
      allBookmarks.push(article);
      this.storage.set("bookmarks", allBookmarks);
    }
  }

  async removeFromBookmarks(id: number, type: string) {
    const searchResponse = await this.searchBookmarks(id);
    const allBookmarks = await this.getBookmarks();
    if (searchResponse) {
      let index: number;
      allBookmarks.forEach((bookmark) => {
        if (
          (bookmark["id"] == id || bookmark["catID"] == id) &&
          bookmark["type"] == type
        ) {
          index = allBookmarks.indexOf(bookmark);
        }
      });

      allBookmarks.splice(index, 1);

      this.storage.set("bookmarks", allBookmarks);
    }
  }

  async clearBookmarks() {
    this.storage.set("bookmarks", []);
  }

  async getDownloads() {
    return await this.storage.get("downloads");
  }

  async searchDownloads(id: number) {
    const allDownloads = await this.getDownloads();
    if (!allDownloads || allDownloads.length === 0) {
      return null;
    } else {
      const foundDownload = allDownloads.find((download) => {
        return download["id"] == id;
      });

      if (foundDownload) {
        return foundDownload;
      } else {
        return null;
      }
    }
  }

  async addToDownloads(article: object) {
    const searchResponse = await this.searchDownloads(article["id"]);
    const allDownloads = await this.getDownloads();
    if (!allDownloads) {
      this.storage.set("downloads", [article]);
      return;
    } else if (!searchResponse) {
      allDownloads.push(article);
      this.storage.set("downloads", allDownloads);
    }

    this.cacheImages(article);
  }

  async removeFromDownloads(id: number) {
    const searchResponse = await this.searchDownloads(id);
    const allDownloads = await this.getDownloads();
    if (searchResponse) {
      let index: number;
      allDownloads.forEach((download) => {
        if (download["id"] == id) {
          index = allDownloads.indexOf(download);
        }
      });

      allDownloads.splice(index, 1);

      this.storage.set("downloads", allDownloads);
    }
  }

  async clearDownloads() {
    this.storage.set("downloads", []);
  }

  cacheImages(article) {
    let images = [];
    images.push(article["image"]);

    let contents = article["content"] || [];
    contents.forEach((content) => {
      (content["slides"] || []).forEach((slide) => {
        images.push(slide["image"]);
      });
      images.push(content["url"]);
    });

    images.forEach((url) => {
      if (url) {
        ImgCache.cacheFile(url, () => {});
      }
    });
  }

  async getActivity() {
    return await this.storage.get("activity");
  }

  async searchActivity(id: number) {
    const allActivity = await this.getActivity();
    if (!allActivity) {
      return null;
    }
    const result = allActivity.find((article) => article["id"] === id);
    if (result !== undefined) {
      return result;
    } else {
      return null;
    }
  }

  async addToActivity(article: object) {
    if (!article["id"]) {
      return;
    }

    const activity = await this.getActivity();
    if (!activity) {
      this.storage.set("activity", [article]);
      return;
    } else if (activity.length < 20) {
      let index: number;
      activity.forEach((item) => {
        if (item["id"] == article["id"]) {
          index = activity.indexOf(item);
          activity.splice(index, 1);
        }
      });

      activity.push(article);
      this.storage.set("activity", activity);
    }
  }

  async clearActivity() {
    this.storage.set("activity", []);
  }

  async getFeedback() {
    return await this.storage.get("feedback");
  }

  async searchFeedback(id) {
    const allFeedback = await this.getFeedback();
    if (!allFeedback) {
      return false;
    }
    id = id.toString();
    if (allFeedback.includes(id)) {
      return true;
    } else {
      return false;
    }
  }

  async addToFeedback(id: number) {
    const searchResponse = await this.searchFeedback(id);
    const allFeedback = await this.getFeedback();
    if (!searchResponse) {
      allFeedback.push(id);
      this.storage.set("feedback", allFeedback);
    }
  }

  async getRecentSearch() {
    const recentSearch = await this.storage.get("recentSearch");
    if (!recentSearch) {
      this.storage.set("recentSearch", []);
      return [];
    } else {
      return recentSearch;
    }
  }

  async searchRecentSearch(keyword: string) {
    const allSearches = await this.getRecentSearch();
    if (!allSearches) {
      return null;
    }

    const result = allSearches.find((search) => search == keyword);
    if (result !== undefined) {
      return result;
    } else {
      return null;
    }
  }

  async addToRecentSearch(keyword: string) {
    const searchResponse = await this.searchRecentSearch(keyword);
    const allSearches = await this.getRecentSearch();
    if (searchResponse && allSearches.length < 20) {
      let index: number;

      allSearches.forEach((item) => {
        if (item == keyword) {
          index = allSearches.indexOf(item);
        }
      });

      allSearches.splice(index, 1);
    }
    allSearches.push(keyword);

    this.storage.set("recentSearch", allSearches);
  }

  async getHowToSearch() {
    const howToSearch = await this.storage.get("howToSearch");
    if (!howToSearch) {
      this.storage.set("howToSearch", []);
      return [];
    } else {
      return howToSearch;
    }
  }

  async searchHowToSearch(id: number) {
    const allSearches = await this.getHowToSearch();
    if (!allSearches) {
      return null;
    }
    const result = allSearches.find((search) => search["id"] === id);
    if (result !== undefined) {
      return result;
    } else {
      return null;
    }
  }

  async addToHowToSearch(search: object) {
    const searchResponse = await this.searchHowToSearch(search["id"]);
    const allSearches = await this.getHowToSearch();
    if (searchResponse && allSearches.length < 20) {
      let index: number;

      allSearches.forEach((item) => {
        if (item["id"] === search["id"]) {
          index = allSearches.indexOf(item);
        }
      });

      allSearches.splice(index, 1);
    }

    allSearches.push(search);

    this.storage.set("howToSearch", allSearches);
  }

  isNative(): boolean {
    return this.platform.is("cordova");
  }
}
