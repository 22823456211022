import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ThemeDirective } from "./theme.directive";
import { ImagecacheDirective } from "./imagecache.directive";

@NgModule({
  imports: [IonicModule, CommonModule],
  declarations: [ThemeDirective, ImagecacheDirective],
  exports: [ThemeDirective, ImagecacheDirective],
})
export class DirectivesModule {}
