import { Component, ViewChild } from "@angular/core";
import {
  Platform
} from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { CachingService } from "./services/caching/caching.service";
import { FetchingService } from "./services/fetching/fetching.service";

import StaticTexts from "./Models/StaticTexts.model";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  public staticTexts: StaticTexts;
  public loaded: boolean = false;

  @ViewChild("app") app;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private fetching: FetchingService,
    private storage: Storage,
    private caching: CachingService
  ) {}

  ngOnInit() {
    this.initializeApp();
  }

  public async initializeApp(): Promise<any> {
    await this.platform.ready();
    await this.storage.ready();

    this.caching.initializeStorage();
    await this.caching.initializeCollections();
    await this.fetching.getAvailableLanguages();
    await this.fetching.getAvailableRegions();
    await this.fetching.getAvailableCountries();
    await this.fetching.setAppLanguage();
    this.staticTexts = await this.fetching.getTranslations();

    if (this.isNative) {
      this.statusBar.styleDefault();
    }
    this.splashScreen.hide();
  }  

  public isNative(): boolean {
    return this.platform.is("cordova");
  }
}
