import { Injectable } from "@angular/core";

import { FetchingService } from "../../services/fetching/fetching.service";
import { HttpCacheService } from "../../services/http-cache/http-cache.service";

import { environment } from "../../../environments/environment";
import { Storage } from "@ionic/storage";
import FormattedArticle from "src/app/Models/Article.model";

@Injectable({
  providedIn: "root",
})
export class ArticlesService {
  SHARE_URL = environment.shareUrl;
  BASE_URL = environment.baseUrl;
  BASICS_PRODUCTS_COLLECTION = environment.basicsProductsCollection;

  constructor(
    private httpCache: HttpCacheService,
    private fetching: FetchingService,
    private storage: Storage
  ) {}

  fetchPolicies(policyList) {
    const url = `${this.BASE_URL}/policies?policies=${policyList}`;
    return this.httpCache.get(url, false, true).then((data) => {
      if (data && data.sets.policies.length > 0) {
        let translations = [];
        data.sets.translations[0].translationData.policies.forEach((policy) => {
          translations.push(this.toArticle(policy));
        });
        
        return translations;
      }
    });
  }

  fetchWhatsNew(readCache = false, writeCache = true) {
    const url = `${this.BASE_URL}/articles?new=true`;

    return this.httpCache.get(url, readCache, writeCache).then((data) => {
      const sets: Map<String, Array<Map<String, any>>> =
        (data || {})["sets"] || {};
      const translations = sets["translations"] || {};

      let articleTranslations = [];
      for (const [collection, articles] of Object.entries(sets)) {
        if (collection !== "translations") {
          const trans = articles
            .map((data) => {
              let tran = Object.values(translations).find((translation) => {
                return translation["translationPostID"] === data["_id"];
              });
              if (tran) {
                tran["collection"] = collection;
              }
              return tran;
            })
            .filter(function (el) {
              return el;
            });
          articleTranslations = articleTranslations.concat(trans);
        }
      }

      let tomorrow;
      const generatedDate = new Date();
      generatedDate.setMonth(generatedDate.getMonth() + 1);
      generatedDate.setDate(generatedDate.getDate() + 1);
      const day = generatedDate.getDate();
      const month = generatedDate.getMonth();
      const year = generatedDate.getFullYear();
      tomorrow = year + "-" + month + "-" + day;

      return articleTranslations.map((data) => {
        data["newUntil"] = tomorrow;
        return this.toArticle(data);
      });
    });
  }

  fetchCollection(collection, readCache = false, writeCache = true) {
    const url = `${this.BASE_URL}/articles?collection=${collection}&page_size=100`;
    return this.httpCache.get(url, readCache, writeCache).then((data) => {
      if (data) {
        return data["sets"][collection];
      } else {
        return null;
      }
    });
  }

  determineCollection(articleId) {
    Promise.all([
      this.storage.get("Support Articles"),
      this.storage.get("The Basics Articles - Equipment"),
      this.storage.get(this.BASICS_PRODUCTS_COLLECTION),
    ]).then((response) => {
      if (response[0].includes(articleId)) {
        return "Support Articles";
      } else if (response[1].includes(articleId)) {
        return "The Basics Articles - Equipment";
      } else if (response[2].includes(articleId)) {
        return this.BASICS_PRODUCTS_COLLECTION;
      } else {
        return null;
      }
    });
  }

  getArticleById(id, collection, readCache = false, writeCache = true) {
    return this.fetching.getLang().then((lang) => {
      const url = `${this.BASE_URL}/articles?id=${id}&collection=${collection}`;

      return this.httpCache.get(url, readCache, writeCache).then((data) => {
        if (data) {
          if (
            data["sets"][collection].length > 0 &&
            data["sets"]["translations"] !== undefined
          ) {
            const articleData = data["sets"]["translations"][0];
            const defaultObject = data["sets"][collection].find(
              (article) => article["_id"] == id
            );
            articleData["collection"] = collection;
            articleData["newUntil"] = data["sets"][collection][0]["new-until"];

            if (defaultObject) {
              const defaultImg = defaultObject["image"] || {};
              articleData["defaultImage"] = defaultImg["src"];
              return this.toArticle(articleData);
            } else {
              return null;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }
      });
    });
  }

  getArticleAsAdmin(
    id,
    collection,
    token,
    readCache = false,
    writeCache = true
  ): Promise<void | FormattedArticle> {
    return this.fetching.getLang().then((lang) => {
      const url = `${this.BASE_URL}/admin-articles?collection=${collection}&id=${id}&lang=${lang}&token=${token}`;

      return this.httpCache
        .get(url, readCache, writeCache)
        .then((data) => {
          let articleData = data["sets"]["translations"][0];
          articleData["collection"] = Object.keys(data["sets"])[0];
          return this.toArticle(articleData);
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  searchArticles(search, readCache = false, writeCache = true) {
    return this.fetching.getLang().then((lang) => {
      const token = sessionStorage.getItem("sessionId");
      const url = `${this.BASE_URL}/search?s=${search}&lang=${lang}&token=${token}`;

      return fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          const translations = data["sets"]["translations"];

          return Promise.all([
            this.fetchCollection("Support Articles").then((response) => {
              translations.forEach((translation) => {
                let found = (response || []).find((item) => {
                  return item["_id"] == translation["translationPostID"];
                });

                if (found) {
                  translation["collection"] = "Support Articles";
                }
              });
            }),

            this.fetchCollection("The Basics Articles - Equipment").then(
              (response) => {
                translations.forEach((translation) => {
                  let found = (response || []).find((item) => {
                    return item["_id"] == translation["translationPostID"];
                  });

                  if (found) {
                    translation["collection"] =
                      "The Basics Articles - Equipment";
                  }
                });
              }
            ),

            this.fetchCollection(this.BASICS_PRODUCTS_COLLECTION).then(
              (response) => {
                translations.forEach((translation) => {
                  let found = response.find((item) => {
                    return item["_id"] == translation["translationPostID"];
                  });

                  if (found) {
                    translation["collection"] = this.BASICS_PRODUCTS_COLLECTION;
                  }
                });
              }
            ),
          ]).then(() => {
            return translations.map((articleData) => {
              return this.toArticle(articleData);
            });
          });
        });
    });
  }

  toArticle(data) {
    if (!data) {
      return null;
    } else {
      const translationData = data["translationData"];
      let content = [];
      let slide = Object.values(translationData["slide"] || []);
      let image = translationData["img"];
      let paragraph = translationData["desc"];

      if (slide && slide.length > 0) {
        const slides = slide
          .map((slide) => {
            const image = (slide["img"] || {})["url"];
            const text = slide["desc"];
            const title = slide["title"];
            const videoId = translationData["img"]["video-id"];

            if (image !== "" || text !== "" || title !== "") {
              return {
                image: image,
                text: text,
                title: title,
                videoId: videoId,
              };
            } else {
              return null;
            }
          })
          .filter(function (el) {
            return el;
          });
        content.push({
          type: "slider",
          slides: slides,
        });
      }

      if (image) {
        content.push({
          type: "image",
          src: image["src"],
        });
      }

      if (paragraph) {
        content.push({
          type: "paragraph",
          text: paragraph,
        });
      }

      return {
        image:
          translationData["img"] !== ""
            ? translationData["img"]["url"]
            : data["defaultImage"],
        id: data["translationPostID"],
        url: `${this.SHARE_URL}/articles/${data["translationPostID"]}`,
        title: translationData["title"],
        description: translationData["desc"],
        type: "article",
        collection: data["collection"],
        equipment: ``,
        content: content,
        isDraft: data["isDraft"],
        newUntil: data["newUntil"],
        articleLanguage: data["translationCode"],
        buttonText: translationData["btn_text"]
          ? translationData["btn_text"]
          : null,
      };
    }
  }
}
