import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Storage } from "@ionic/storage";
import { ToastController, Platform } from "@ionic/angular";
import { Network } from "@ionic-native/network/ngx";

import { FetchingService } from "../../services/fetching/fetching.service";
import { environment } from "../../../environments/environment";

export enum ConnectionStatus {
  Online,
  Offline,
}

@Injectable({
  providedIn: "root",
})
export class HttpCacheService {
  status: ConnectionStatus;
  language: string;
  region: string;
  BASICS_PRODUCTS_COLLECTION = environment.basicsProductsCollection;

  constructor(
    private http: HttpClient,
    private storage: Storage,
    private network: Network,
    private toastController: ToastController,
    private platform: Platform,
    private fetching: FetchingService
  ) {
    this.platform.ready().then(() => {
      this.initializeNetworkEvents();
      this.status =
        this.network.type !== "none"
          ? ConnectionStatus.Online
          : ConnectionStatus.Offline;
    });
  }

  get(urlParam, readCache = false, writeCache = true, setLangAndRegion = true) {
    let forceReadCache = false;
    if ((<any>window)["forceReadCache"] === true) {
      forceReadCache = true;
      readCache = true;
    }

    return Promise.all([
      this.fetching.getLang().then((lang) => {
        if (lang) {
          this.language = lang;
        }
      }),

      this.fetching.getReg().then((reg) => {
        if (reg) {
          this.region = reg;
        }
      }),
      this.fetching.getAvailableRegions().then((regions) => {
        return regions;
      }),
    ]).then((response) => {
      const regions = response[2];
      const foundRegion = regions.find((region) => {
        return region["catID"] === this.region;
      });
      const regionId = foundRegion
        ? foundRegion["catID"]
        : environment.defaultRegionID;
      let url;
      if (setLangAndRegion) {
        url = `${urlParam}&lang=${this.language}&region=${regionId}`;
      }

      url = encodeURI(url);

      if (this.isOffline()) {
        readCache = true;
      }

      return this.getData(url).then((fetchedData) => {
        if (readCache) {
          if (fetchedData) {
            return fetchedData;
          } else {
            console.error("No cached data", url);
          }

          if (forceReadCache) {
            return {};
          }
        }

        return this.http
          .get(url)
          .toPromise()
          .then((data) => {
            const collection = Object.keys(data["sets"])[0];
            if (
              collection === "Policies" ||
              collection === "Pages" ||
              collection === "Support Articles" ||
              collection === "The Basics Articles - Equipment" ||
              collection === this.BASICS_PRODUCTS_COLLECTION
            ) {
              let translations = data["sets"]["translations"];

              if (data["sets"][collection].length > 0 && translations) {
                const translationData = translations[0]["translationData"];
                if (
                  collection === "Pages" &&
                  !translationData["desc"] &&
                  !translationData["img"] &&
                  !translationData["title"]
                ) {
                  const englishUrl = `${urlParam}&lang=en-us&region=${regionId}`;
                  return this.http
                    .get(englishUrl)
                    .toPromise()
                    .then((data) => {
                      if (writeCache) {
                        this.setData(englishUrl, data);
                      }
                      return data;
                    });
                } else {
                  if (writeCache) {
                    this.setData(url, data);
                  }
                  return data;
                }
              }
            } else {
              if (writeCache) {
                this.setData(url, data);
              }
              return data;
            }
          });
      });
    });
  }

  getData(url) {
    return this.storage.get(url);
  }

  setData(url, data) {
    if (this.isNative()) {
      this.storage.set(url, data);
    }
  }

  isNative(): boolean {
    return this.platform.is("cordova");
  }

  downloadAllCache() {
    return Promise.all([
      this.fetching.getLang().then((lang) => {
        return lang;
      }),
      this.fetching.getReg().then((reg) => {
        return reg;
      }),
      this.fetching.getAvailableRegions().then((regions) => {
        return regions;
      }),
    ]).then((response) => {
      const lang = response[0];
      const regions = response[2];
      const regionID = response[1];
      const foundRegion = regions.find((region) => {
        return region["catID"] === regionID;
      });
      const regionId = foundRegion["catID"];

      const cacheEnv = environment.cacheEnv;
      const awsBucket = environment.awsBucket;
      const url = `https://s3.us-east-2.amazonaws.com/${awsBucket}/daily_data_dumps/env_${cacheEnv}__region_${regionId}__lang_${lang}.json`;

      return this.http
        .get(url)
        .toPromise()
        .then((data: Array<any>) => {
          for (const item of data) {
            const urlToSet = item["url"];
            const json = item["json"];
            this.setData(urlToSet, json);
          }
        });
    });
  }

  public initializeNetworkEvents() {
    this.network.onDisconnect().subscribe(() => {
      if (this.status === ConnectionStatus.Online) {
        this.updateNetworkStatus(ConnectionStatus.Offline);
      }
    });

    this.network.onConnect().subscribe(() => {
      if (this.status === ConnectionStatus.Offline) {
        this.updateNetworkStatus(ConnectionStatus.Online);
      }
    });
  }

  private async updateNetworkStatus(status: ConnectionStatus) {
    this.status = status;
    const connection =
      status == ConnectionStatus.Offline ? "Offline" : "Online";
    const toast = this.toastController.create({
      message: `You are now ${connection}`,
      duration: 3000,
      position: "bottom",
    });
    toast.then((toast) => toast.present());
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status;
  }

  public isOffline() {
    return this.status === ConnectionStatus.Offline;
  }
}
