export const environment = {
  production: true,
  env: "cih",
  appTitle: "CaseIH - Know How",
  themeDirectory: "cih",
  shareUrl: "http://knowhow.caseih.com",
  baseUrl: "https://case-ih-prod.herokuapp.com/api",
  basicsEquipmentCollection: "The Basics Articles - Equipment",
  basicsProductsCollection: "The Basics Articles - AFS Products",
  basicsEquipmentSlug: "the-basics-equipment",
  basicProductsSlug: "the-basics-afs-products",
  defaultLanguageSlug: "en-us",
  defaultRegionID: "276",
  equipmentCategorySlug: "equipment",
  productsCategorySlug: "afs-products",
  equipmentKey: "equipment",
  productsKey: "afs-products",
  equipmentArticles: "equipment-articles",
  productsArticles: "afs-product-articles",
  cacheUrl:
    "https://s3.us-east-2.amazonaws.com/cih-in-cab-support/daily_data_dumps/",
  cacheEnv: "cih-prod",
  tempDefaultHowToEquipment: "452",
  tempDefaultHowToProduct: "462",
  tempDefaultHowToEquipmentTitle: "Magnum Series",
  tempDefaultHowToProductTitle: "AFS Pro 1200",
  privacyPolicyPageID: "36",
  termsAndConditionsPageID: "37",
  dataStoragePolicyPageID: "38",
  eulaPageID: "37",
  operatorsManualNoticePageID: "68",
  mappPageID: "36",
  googleAnalyticsKey: "G-5TK2JLRFTL",
  awsBucket: "cih-in-cab-support",
  googleGeocodingAPIKey: "AIzaSyDKwBZKSAecY90e_nR2kl6Ne3QvQrrSvbY",
};
